import { Flex, FlexProps } from "@chakra-ui/react"
import { ReactNode, useLayoutEffect, useRef, useState } from "react"
import { ClientSide } from "~/components/ClientSide"
import useElementSize from "~/utils/hooks/useElementSize"

interface Props<T> extends FlexProps {
  defaultHeight?: number
  overflowIndicator?:
    | ReactNode
    | ((overflowingItems: number, data: T[]) => ReactNode)
  data?: T[]
}

const ClientSideFlexOneLine = <T,>(props: Props<T>) => {
  return (
    <ClientSide>
      <FlexOneLine {...props} />
    </ClientSide>
  )
}

const useFlexOneLine = <T,>({
  defaultHeight = 34,
  overflowIndicator,
  children,
  data,
}: Props<T>) => {
  const [flexHeight, setFlexHeight] = useState<number>(defaultHeight)

  const [flexRef, dimensions] = useElementSize()
  const overflowIndicatorRef = useRef<HTMLDivElement>(null)

  // The +1 is to account for rounding errors
  const totalWidth = dimensions?.width + 2

  const childrenWidth = Array.from(flexRef.current?.children ?? [])
    .filter((child) => {
      return (
        child instanceof HTMLElement &&
        child.dataset.attribute !== "more-indication"
      )
    })
    .map((child) => child.getBoundingClientRect().width)

  const flexGap = flexRef.current
    ? +getComputedStyle(flexRef.current).rowGap.replace("px", "")
    : 0

  const overflowWidth =
    overflowIndicatorRef.current?.getBoundingClientRect().width ?? 0

  let threshold = 0
  const overflowing = childrenWidth.findIndex((width, idx) => {
    const nextWidth = width + (idx === 0 ? 0 : flexGap)
    if (threshold + nextWidth + overflowWidth > totalWidth) {
      return true
    }

    threshold += nextWidth
    return false
  })

  const childrenWithMore = Array.isArray(children)
    ? [...children.flat(Infinity)]
    : [children]

  const insertAt =
    threshold + flexGap + overflowWidth < totalWidth
      ? overflowing
      : overflowing - 1

  if (overflowIndicator && overflowing > 0) {
    childrenWithMore.splice(
      insertAt,
      0,
      <Flex
        data-attribute="more-indication"
        key="more"
        alignSelf="stretch"
        ref={overflowIndicatorRef}
        height={`${flexHeight}px`}
      >
        {typeof overflowIndicator === "function"
          ? overflowIndicator(
              childrenWithMore.length - insertAt,
              data?.slice(insertAt) ?? []
            )
          : overflowIndicator}
      </Flex>
    )
  }

  useLayoutEffect(() => {
    if (flexRef.current) {
      if (flexRef.current.children.length) {
        const currentHeight =
          flexRef.current.children[0].getBoundingClientRect().height

        setFlexHeight(currentHeight)
      }
    }
  }, [flexRef])

  return {
    flexRef,
    flexHeight,
    childrenWithMore,
    insertAt,
    overflowing,
  }
}

const FlexOneLine = <T,>({
  defaultHeight,
  overflowIndicator,
  data,
  children,
  ...props
}: Props<T>) => {
  const { childrenWithMore, flexRef, flexHeight } = useFlexOneLine({
    defaultHeight,
    overflowIndicator,
    data,
    children,
  })

  return (
    <Flex
      ref={flexRef}
      flexWrap="wrap"
      overflow="hidden"
      height={`${flexHeight + 2}px`}
      p={"1px"}
      {...props}
    >
      {childrenWithMore}
    </Flex>
  )
}

type FlexNLinesProps<T> = Props<T> & { numberOfLines: number }

const FlexNLinesLoop = <T,>({
  numberOfLines = 1,
  overflowIndicator,
  children,
  ...props
}: FlexNLinesProps<T>) => {
  const { childrenWithMore, flexHeight, insertAt, overflowing, flexRef } =
    useFlexOneLine({
      children,
      ...(numberOfLines === 1 && { overflowIndicator }), // Only account for overflow indicator if it's the last line
      ...props,
    })

  const currentChildren = childrenWithMore.slice(
    0,
    overflowing > 0 ? insertAt : Infinity
  )

  const nextChildren =
    overflowing > 0 ? childrenWithMore.slice(insertAt + 1) : []

  if (numberOfLines === 1 || nextChildren.length === 0) {
    return (
      <Flex
        ref={flexRef}
        flexWrap="wrap"
        overflow="hidden"
        height={`${flexHeight + 2}px`}
        p={"1px"}
        {...props}
        alignItems="flex-start"
      >
        {childrenWithMore}
      </Flex>
    )
  }

  return (
    <>
      <Flex ref={flexRef} {...props} alignItems="flex-start">
        {currentChildren}
      </Flex>
      <FlexNLinesLoop
        numberOfLines={numberOfLines - 1}
        overflowIndicator={overflowIndicator}
        {...props}
      >
        {nextChildren}
      </FlexNLinesLoop>
    </>
  )
}

export const FlexNLines = <T,>({
  numberOfLines = 1,
  ...props
}: FlexNLinesProps<T>) => {
  return (
    // <Box>

    // </Box>
    // <Flex direction="column" gap={props.gap} rowGap={props.rowGap}>
    // </Flex>
    <FlexNLinesLoop numberOfLines={numberOfLines} {...props} />
  )
}

export { ClientSideFlexOneLine as FlexOneLine }
export type { Props as FlexOneLineProps }
